import * as React from 'react'
import { useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import { IconSearchDL } from '../components/Icons'
import AsAdvertisedOn from '../components/AsAdvertisedOn'
import useInfinteScroll from '../hooks/useInfiniteScroll'
import { processGatsbyImageDataMock } from '../common/gatsbyImageData'
import PhoneLink from '../components/PhoneLink'

const HeroImageUrl = ({ url, alt, className }) => {
  return <img src={url} alt={alt} className={className} width={360} height={360} />
}
HeroImageUrl.propTypes = {
  url: PropTypes.string,
  alt: PropTypes.string,
  className: PropTypes.string,
}

const NewsResults = () => {
  const pageSize = 24
  const [limit, setLimit] = useState(pageSize)
  const [keyword, setKeyword] = useState('')
  const { blogs } = useStaticQuery(
    graphql`
      query {
        blogs: allStrapiBlogPost(sort: { order: DESC, fields: createdAt }) {
          nodes {
            id
            title
            seo {
              metaTitle
              metaDescription
            }
            url: urlPath
            seo {
              metaTitle
              metaDescription
              metaImage {
                gatsbyImageDataMock
              }
            }
            createdAt
            updatedAt
          }
        }
      }
    `
  )
  blogs.nodes.forEach(blog => {
    processGatsbyImageDataMock(blog.seo?.metaImage)
  })

  const loadMore = React.useCallback(() => {
    setLimit(limit => limit + pageSize)
  }, [setLimit])
  const nodeRef = useInfinteScroll(limit, loadMore)

  const filteredNews = blogs.nodes.filter(
    b => b.title.toLowerCase().indexOf(keyword.toLowerCase()) >= 0
  )

  return (
    <Layout breadcrumbs={true} className="mx-auto" canonical={'/news/'} title={'NEWS'}>
      <Seo
        title={'Gray & Sons Jewelry Watch News'}
        canonical={'/news/'}
        description={
          'Gray & Sons Jewelry & Watch NEWS, Inside scoop & expertise! | Jewelry & Watch Specialists Blog.'
        }
      />

      <section className="flex flex-col w-full items-center justify-center">
        <div className="flex flex-col items-center justify-center w-11/12 max-w-[1366px]">
          <h1 className="text-center text-2xl uppercase md:text-2xl xl:text-3xl max-w-[500px] my-5 lg:my-0">
            GRAY &amp; Sons Jewelry &amp; Watch NEWS, Inside scoop &amp; expertise!
          </h1>
        </div>
        <form
          className="justify-self-end hidden w-full grow min-w-max max-w-[700px] md:block mx-auto my-5"
          action={'/news/'}
          method={'get'}
          onSubmit={e => e.preventDefault()}
        >
          <div
            className={
              'hidden border w-full mx-auto border-gray-300 rounded-full md:flex flex-row hover:shadow-lg focus:shadow-lg my-3 duration-300'
            }
          >
            <input
              type={'text'}
              name={'q'}
              placeholder={'Search'}
              value={keyword}
              id={'search-keywords'}
              aria-label="Search"
              onChange={e => {
                setKeyword(e.target.value)
              }}
              className={' py-3 px-5 w-full rounded-l-full  placeholder-gray-500 outline-0'}
            />
            <button className={'text-gray-500 h-10 my-auto px-5 border-l border-gray-300 '}>
              <IconSearchDL />
              <span className={'sr-only'}>Search</span>
            </button>
          </div>
        </form>
      </section>
      <div className={'w-full max-w-[1366px] mx-auto px-5 lg:px-10 pb-20'}>
        <section
          title={'Watch News'}
          className={'w-11/12 mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5'}
        >
          {filteredNews.slice(0, limit).map((blog, index) => {
            return (
              <Link
                to={blog.url}
                key={index}
                className={
                  'flex flex-col justify-between p-7 rounded-lg shadow-md border border-gray-200 group w-full mx-auto'
                }
              >
                <div className={'mx-auto w-10/12 max-w-80 flex'}>
                  {blog.hero_image_url ? (
                    <HeroImageUrl
                      image={blog.seo?.metaImage}
                      alt={blog.seo?.metaTitle}
                      className={
                        'aspect-square w-10/12 mx-auto rounded-2xl object-cover group-hover:scale-[102%] duration-200'
                      }
                    />
                  ) : (
                    <StaticImage
                      src={'../images/top-watch-buyer-img.png'}
                      alt={blog.seo?.metaTitle}
                      className={
                        'aspect-square w-10/12 mx-auto rounded-2xl object-cover group-hover:scale-[102%] duration-200'
                      }
                    />
                  )}
                </div>
                <h2 className="mb-2 h-12 line-clamp-2 font-bold">{blog.title}</h2>
                <p className={'pb-4 flex flex-grow text-gray-600'}>{blog.seo?.metaDescription}</p>
                <p
                  className={'group-hover:text-red-700 font-bold'}
                  aria-label={blog.title}
                  to={blog.url}
                >
                  Read more <span className="sr-only">About {blog.title}</span>
                </p>
              </Link>
            )
          })}
          {limit < filteredNews.length && (
            <button
              ref={nodeRef}
              className={'btn-cta p-4 mx-auto grid col-span-1 md:col-span-2 lg:col-span-3 '}
              onClick={loadMore}
            >
              Load More
            </button>
          )}
        </section>
      </div>
      <AsAdvertisedOn />
      <section className="flex w-full flex-col justify-center py-10">
        <div className="flex w-full flex-col items-center justify-center ">
          <PhoneLink className="text-3xl font-bold text-accent hover:text-gray-500">
            <h3 className="px-10 text-center text-3xl uppercase">
              Call Us Now: 305 865 0999
            </h3>
          </PhoneLink>
          <br />
          <div className="w-full shadow-xl"> &nbsp;</div>
          <h2 className="p-10 text-center uppercase text-gray-800">
            The Purchasing and Consignment Division of <br />
          </h2>
          <br />
          <a href="/catalog-request">
            <StaticImage
              src={'../images/svg/gray-and-sons-logo.svg'}
              alt="Gray and Sons Jewelers Logo"
            />
          </a>
          <br /> <br /> <br />
          <h2 className="w-full p-10 text-center text-2xl font-black uppercase">
            Be the first to know | SEE OUR CATALOG
          </h2>
          <a href="/catalog-request" className="flex w-full justify-center">
            <StaticImage
              src={'../images/gray-and-sons-request-catalog-banner.jpeg'}
              alt="Gray and Sons Catalog Subscription"
            />
          </a>
        </div>
      </section>
    </Layout>
  )
}

export default NewsResults
